/* eslint no-console: 0, no-unused-vars: 0, no-undef: 0 */
import Vue from 'vue'
import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations'

let _sentry = null
// Added RELEASE_TAG !== false so that the tests will work,
// see jest.config.js
if (typeof RELEASE_TAG !== 'undefined' && RELEASE_TAG !== '' && RELEASE_TAG !== false) {
  Sentry.init({
    dsn: 'https://e9ae7d8c41094d28bcb35526e8f23abb@sentry.io/1242530',
    release: RELEASE_TAG,
    sampleRate: 0.25,
    environment: window.location.href.includes('snstaging.com') ? 'staging' : process.env.RAILS_ENV,
    integrations: [
      new Integrations.Vue({
        Vue,
        attachProps: true,
        logError: true
      })
    ],
    ignoreErrors: [
      /NS_ERROR_NOT_INITIALIZED/,
      /captured with keys: isTrusted/,
      /captured with keys: currentTarget, detail, isTrusted, target/,
      // per the below github issue, seems like community consensus is
      // this specific error can safely be ignored
      // https://github.com/vuejs/vue-router/issues/2963
      'NavigationDuplicated',
      'Object Not Found Matching Id' // some kind of outlook error. really noisy when it happens and isn't a concern https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062/23
    ],
    denyUrls: [
      'https://cdn.evgnet.com/beacon/cmgfinancial/engage/scripts/evergage.min.js',
      'https://pi.pardot.com/analytics'
    ],
    beforeSend (event) {
      // This should turn off errors in IE browsers
      // document.documentMode only exists in IE.
      if (document.documentMode) return null

      // Normally this should go in sentry's options object under denyUrls. Unfortunately
      // this doesn't work for google analytics because the actual analytics script is
      // dynamically loaded through an iife anonymous function in an html script embedded in an
      // erb template. Because we use anonymous functions like this for lots of things,
      // it's unsafe to ignore the anonymous source. So this check is a workaround to let us
      // ignore any google analytics errors
      // 09/12/2021
      const errorComesFromGoogleAnalyticsScript = !!event?.exception?.values[0]?.stacktrace?.frames?.filter(frame => /.+googletagmanager.com?.+/.test(frame.filename))?.length
      if (errorComesFromGoogleAnalyticsScript) return null

      return event
    }
  })
  _sentry = Sentry
} else {
  _sentry = {
    captureException: (err) => {
      console.log('Sentry: Capture Exception ', err)
    },
    captureMessage: (err) => {
      console.log('Sentry: Capture Message ', err)
    },
    captureEvent: (err) => {
      console.log('Sentry: Capture Event ', err)
    },
    withScope: (callback) => {
      const scope = {
        setExtra (key, item) {
          console.log(`Sentry: Set Extra ${key}: `, item)
        },
        setTag: (tag, value) => {
          console.log(`Sentry: Set Tag ${tag}: ${value}`)
        }
      }
      callback(scope)
    },
    setTag (tag) {
      console.log(`setting tag: ${tag}`)
    },
    setExtra (extra) {
      console.log(`setting extra: ${extra}`)
    }
  }
}

// All `metadata` arguments must be formatted like
// { context, tags }. I would use ES6 object destructuring
// but then I can't check if metadata was actually passed
// in or not
const globalSentry = {
  captureException (err, metadata) {
    if (err) {
      if (err.response) {
        // ignore 401 errors
        if (![400, 401, 403, 404, 500, 502, 503, 504].includes(err.response.status)) {
          this.capturePayload(err, metadata, _sentry.captureException)
        }
      } else {
        this.capturePayload(err, metadata, _sentry.captureException)
      }
    }
  },
  captureMessage (message, metadata) {
    this.capturePayload(message, metadata, _sentry.captureMessage)
  },
  captureEvent (event, metadata) {
    this.capturePayload(event, metadata, _sentry.captureEvent)
  },
  capturePayload (payload, metadata, captureCallback) {
    this.withScope(scope => {
      if (metadata) {
        const { context, tags } = metadata
        if (context) {
          for (const [key, value] of Object.entries(context)) {
            scope.setExtra(key, value)
          }
        }
        if (tags) {
          for (const [key, value] of Object.entries(tags)) {
            scope.setTag(key, value)
          }
        }
      }
      captureCallback(payload)
    })
  },
  withScope (callback) {
    _sentry.withScope(callback)
  }
}

/// Tags Take the form of an object e.g. { key: TagName, value: TagValue }
/// Extras take the same form as a tag e.g. { key: ExtraName, value: ExtraValue }
function sentryClient (tags = [], extras = []) {
  tags.forEach(tag => {
    _sentry.setTag(tag.key, tag.value)
  })

  if (process.env.RAILS_ENV === 'staging' && process.env.CI_COMMIT_REF_NAME) {
    _sentry.setTag('branch', process.env.CI_COMMIT_REF_NAME)
  }

  extras.forEach(extra => {
    _sentry.setExtra(extra.key, extra.value)
  })
  return globalSentry
}

export default sentryClient
