import Vue from 'vue'
import Vuex from 'vuex'
import Sentry from '~/util/sentry'
import Router from 'vue-router'
import App from '~/components/homehub/Landing/App.vue'
import axios from '~/util/axios'
import apolloClient from '~/util/apolloClient'
import vuetify from '~/util/vuetify.js'
import datadog from '~/util/datadog.js'
import { store } from '../store/onboardingStore'
import snackbard from 'snackbard'
import { VueMaskDirective } from 'v-mask'
import VueMoment from 'vue-moment'
import vuexOptions from '../components/homehub/store'

// New SNUI components
import {
  snAlert,
  snAvatar,
  snBtn,
  snDataItem,
  snHeadlineCard,
  snIllustration,
  snLabel,
  snModal,
  snPassword,
  snRadio,
  snRadioGroup,
  snTabs,
  snTextField,
  snToast,
  snCard
} from '@simplenexus/snui'
import '@simplenexus/snui/dist/assets/index.css'

const { VueApollo, provider } = apolloClient()
datadog({ packName: 'landingPage' })

Vue.component('snAlert', snAlert)
Vue.component('snAvatar', snAvatar)
Vue.component('snBtn', snBtn)
Vue.component('snDataItem', snDataItem)
Vue.component('snIllustration', snIllustration)
Vue.component('snHeadlineCard', snHeadlineCard)
Vue.component('snLabel', snLabel)
Vue.component('snModal', snModal)
Vue.component('snPassword', snPassword)
Vue.component('snRadio', snRadio)
Vue.component('snRadioGroup', snRadioGroup)
Vue.component('snTabs', snTabs)
Vue.component('snTextField', snTextField)
Vue.component('snToast', snToast)
Vue.component('snCard', snCard)

Vue.prototype.$sentry = Sentry([{ key: 'pack', value: 'New Landing Page' }])
Vue.prototype.$axios = axios
Vue.prototype.$store = store

if (['development', 'staging'].includes(process.env.RAILS_ENV)) {
  Vue.config.devtools = true
}

Vue.use(snackbard)
Vue.use(Router)
Vue.use(VueMoment)
Vue.use(VueApollo)
Vue.use(Vuex)

const vuexStore = new Vuex.Store(vuexOptions)

Vue.directive('mask', VueMaskDirective)

const router = new Router({
  scrollBehavior (to, from, savedPosition) {
    return { y: 0 }
  },
  mode: 'history',
  routes: [
    {
      path: '/:locale?/homehub/signup/:code',
      name: 'landingPage',
      component: () => import('~/components/homehub/Landing/LandingPage.vue')
    },
    {
      path: '/:locale?/homehub/login/:code?',
      name: 'signIn',
      component: () => import('~/components/homehub/Onboarding/SignIn.vue')
    },
    {
      path: '/:locale?/homehub/sso_login/:code?',
      name: 'loNotFound',
      component: () => import('~/components/homehub/Onboarding/LoNotFound.vue')
    },
    {
      path: '/:locale?/homehub/onboarding/ask-for-email/:code?',
      name: 'askForEmail',
      component: () => import('~/components/homehub/Onboarding/AskForEmail.vue')
    },
    {
      path: '/:locale?/homehub/onboarding/recover-password/:code?',
      name: 'recoverPassword',
      component: () => import('~/components/homehub/Onboarding/RecoverPassword.vue')
    },
    {
      path: '/:locale?/homehub/onboarding/password-email/:code?',
      name: 'passwordEmail',
      component: () => import('~/components/homehub/Onboarding/PasswordEmail.vue')
    },
    {
      path: '/:locale?/homehub/onboarding/email-confirmation/:code?',
      name: 'emailConfirmation',
      component: () => import('~/components/homehub/Onboarding/EmailConfirmation.vue')
    },
    {
      path: '/:locale?/homehub/onboarding/verify-email/:code?',
      name: 'verifyEmail',
      component: () => import('~/components/homehub/Onboarding/VerifyEmail.vue')
    },
    {
      path: '/:locale?/homehub/onboarding/set-password/:code?',
      name: 'setPassword',
      component: () => import('~/components/homehub/Onboarding/SetPassword.vue')
    },
    {
      path: '/:locale?/homehub/onboarding/multiple-loan-officers/:code?',
      name: 'multipleLoanOfficers',
      component: () => import('~/components/homehub/Onboarding/MultipleLoanOfficers.vue')
    },
    {
      path: '/:locale?/homehub/onboarding/register-account/:code?',
      name: 'registerAccount',
      component: () => import('~/components/homehub/Onboarding/RegisterAccount.vue')
    },
    {
      path: '/:locale?/homehub/onboarding/send-verification-code/:code?',
      name: 'sendVerificationCode',
      component: () => import('~/components/homehub/Onboarding/SendVerificationCode.vue')
    },
    {
      path: '/:locale?/homehub/onboarding/enter-verification-code/:code?',
      name: 'enterVerificationCode',
      component: () => import('~/components/homehub/Onboarding/EnterVerificationCode.vue')
    },
    {
      path: '/:locale?/homehub/onboarding/validate-phone/:code?',
      name: 'validatePhone',
      component: () => import('~/components/homehub/Onboarding/ValidatePhone.vue')
    },
    {
      path: '/:locale?/homehub/onboarding/validate-name/:code?',
      name: 'validateName',
      component: () => import('~/components/homehub/Onboarding/ValidateName.vue')
    },
    {
      path: '/:locale?/homehub/onboarding/select-loan-officer/:code?',
      name: 'selectLoanOfficer',
      component: () => import('~/components/homehub/Onboarding/SelectLoanOfficer.vue')
    },
    {
      path: '/:locale?/homehub/onboarding/terms-and-conditions/:code?',
      name: 'termsAndConditions',
      component: () => import('~/components/homehub/Onboarding/TermsAndConditions.vue')
    },
    {
      path: '/:locale?/homehub/onboarding/grant-loan-access/:code?',
      name: 'grantLoanAccess',
      component: () => import('~/components/homehub/Onboarding/GrantLoanAccess.vue')
    },
    // this route must come after all other onboarding routes or path will be confused for sac
    {
      path: '/:locale?/homehub/onboarding/:code',
      name: 'onboardingBaseRoute',
      component: () => import('~/components/homehub/Landing/LandingPage.vue')
    },
    {
      path: '/:locale?/homehub/lo_not_found',
      name: 'loNotFound',
      component: () => import('~/components/homehub/Onboarding/LoNotFound.vue')
    },
    {
      path: '/:locale?/homehub_sso',
      name: 'homehubSSO',
      component: () => import('~/components/homehub/SSO/HomehubSSO.vue')
    },
    {
      path: '/:locale?/homehub/daily_rates/:code',
      name: 'DailyRates',
      component: () => import('~/components/homehub/ConsumerPricing/DailyRates.vue')
    },
    {
      path: '/borrower/login/:code?',
      name: 'login',
      component: () =>
        import('~/components/borrowerApp/borrowerLogin.vue')
    },
    {
      path: '/homehub/mobile_ron_complete',
      name: 'MobileRonComplete',
      component: () => import('../components/disclosures/mobileRonComplete.vue')
    }
  ]
})

new Vue({
  router,
  apolloProvider: provider,
  vuetify,
  render: h => h(App),
  store: { ...vuexStore, ...store }
}).$mount('#app-container')
