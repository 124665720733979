import { ApolloClient } from 'apollo-client'
import { setContext } from 'apollo-link-context'
import { BatchHttpLink } from 'apollo-link-batch-http'
import { HttpLink } from 'apollo-link-http'
import { createUploadLink } from 'apollo-upload-client'
import { onError } from 'apollo-link-error'
import { split } from 'apollo-link'
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory'
import introspectionQueryResultData from './fragmentTypes.json'
import VueApollo from 'vue-apollo'
import Sentry from './sentry'
import 'unfetch/polyfill'
import { merge } from 'lodash'

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData
})

// For unbatched queries
const httpLink = new HttpLink({
  uri: '/graphql',
  transportBatching: true,
  credentials: 'same-origin'
})

// For batched queries
const batchHttpLink = new BatchHttpLink({
  uri: '/graphql',
  batchMax: 15,
  credentials: 'same-origin'
})

const middlewareLink = setContext(() => {
  return {
    headers: {
      'X-CSRF-Token': (document.querySelector('[name=csrf-token]') !== null ? document.querySelector('[name=csrf-token]').content : '')
    }
  }
})

const unbatchedLink = middlewareLink.concat(httpLink)
const batchedLink = middlewareLink.concat(batchHttpLink)

const errorAfterware = onError(({ graphQLErrors, networkError, operation }) => {
  const captureEvent = (msg = 'GraphQL Error', details = {}) => Sentry().captureEvent({ message: msg, extra: { operation, graphQLErrors, networkError, ...details } })

  if (networkError) {
    switch (networkError.statusCode) {
      case 400:
        console.log(networkError)
        captureEvent('GraphQL call returned 400 Bad Request')
        break
      case 401:
        // Redirect to /login on 401s if session is stale
        window.location.replace('/logout')
        break
      case 403:
        console.log(networkError)
        captureEvent('GraphQL call returned 403 Forbidden')
        break
      case 404:
        console.log(networkError)
        captureEvent('GraphQL call returned 404 Not Found')
        break
      case 500:
        // swallow error, don't send to sentry
        console.log(networkError)
        break
      case 502:
        // swallow error, don't send to sentry
        console.log(networkError)
        break
      case 503:
        // swallow error, don't send to sentry
        console.log(networkError)
        break
      case 504:
        // swallow error, don't send to sentry
        console.log(networkError)
        break
    }
  }
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message }) => {
      // auth errors are most often captured by performing redirects to login page
      // ignoring these prevents us from getting noisey usually handled sentry errors
      if (message.toLowerCase().includes('unauthorized')) return
      captureEvent(`[GraphQL error]: ${operation.operationName} - ${message}`)
    })
  }
})

const cache = new InMemoryCache({
  dataIdFromObject: object => object.guid || object.id || null,
  fragmentMatcher
})

function apolloClient (localResolvers = [], localDefaults = [], localTypeDefs = [], options = { connectToDevTools: true }) {
  const resolvers = merge(...localResolvers)
  const typeDefs = merge(...localTypeDefs)
  const defaults = merge(...localDefaults)

  const client = new ApolloClient({
    // Use context switching to determine whether
    // query should be batched
    link: errorAfterware.concat(split(
      operation => operation.getContext().important === true,
      unbatchedLink,
      split(
        operation => operation.getContext().hasUpload,
        createUploadLink({ url: '/graphql', credentials: 'same-origin' }),
        batchedLink
      )
    )),
    resolvers,
    cache,
    typeDefs: typeDefs,
    connectToDevTools: ['development', 'staging'].includes(process.env.RAILS_ENV) && options.connectToDevTools,
    shouldBatch: true
  })

  if (defaults) {
    cache.writeData({ data: defaults })
  }

  const provider = new VueApollo({
    defaultClient: client
  })

  return { client, provider, VueApollo }
}

export default apolloClient
