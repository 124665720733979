const state = () => ({
  voieIframeOpen: false,
  coboVoieIframeOpen: false,
  voaIframeOpen: false
})

const getters = {
  getVoieIframeOpen: ({ voieIframeOpen }) => voieIframeOpen,
  getCoboVoieIframeOpen: ({ coboVoieIframeOpen }) => coboVoieIframeOpen,
  getVoaIframeOpen: ({ voaIframeOpen }) => voaIframeOpen
}

const mutations = {
  setVoieIframeOpen (state, voieIframeOpen) {
    state.voieIframeOpen = voieIframeOpen
  },
  setCoboVoieIframeOpen (state, coboVoieIframeOpen) {
    state.coboVoieIframeOpen = coboVoieIframeOpen
  },
  setVoaIframeOpen (state, voaIframeOpen) {
    state.voaIframeOpen = voaIframeOpen
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations
}
