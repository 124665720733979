import apolloClient from '~/util/apolloClient'
import cloneDeep from 'lodash/cloneDeep'
import {
  createChannel,
  createTypedChannel,
  getChannelMessages,
  createOrUpdateChannelRead,
  getChannel,
  getChannels,
  getCurrentUser,
  MarkAllChannelsAsRead,
  createOrUpdateChatUsersAndChannelForLoan,
  getSuggestedChannels
} from '../../gql/chatQueries.gql'
import Cookie from 'js-cookie'

const apollo = apolloClient().provider.clients.defaultClient

export default {
  namespaced: true,
  state: {
    // Chat workers
    worker: null,
    workerBroadcastChannel: null,

    // Channel data
    currentChannelGuid: null,
    someoneIsTyping: false,
    channels: {
      nodes: [],
      pageInfo: {
        endCursor: null,
        hasNext: false
      }
    },
    isLoadingChannelMessages: false,
    hasUnreadChannels: false,
    scrollChannelsToTop: false,
    confirmChannelCreationDialog: false,
    isLoChatEnabled: true,
    isSendingMessages: false,

    // Message data
    currentMessages: [],
    currentMessagesPageInfo: {
      endCursor: null,
      hasNextPage: false
    },
    allowSendingAttachments: false,
    requireLoanOfficer: true,
    sound: new Audio('/assets/ding.mp3'),
    muted: false,
    typingTimeout: null,

    // User data
    currentUser: {},
    selectedUsers: [],
    selectedOtherUsers: [],
    viewedDisclaimer: null,

    // Misc. chat data
    chatDisclaimer: 'This is the default chat disclaimer.',
    createChatError: '',
    newChat: false,
    toast: {
      active: false,
      description: '',
      event: null,
      message: ''
    },
    isInputEmpty: true,
    imageFiles: []
  },
  getters: {
    usersNotMe: (state, getters) => getters.currentChannel?.users?.filter(u => !u.isMe) ?? [],
    getInitials: () => fullName => {
      const [firstName, lastName] = fullName.split(' ')
      return (firstName[0] + lastName[0]).toUpperCase()
    },
    firstFiveUsersNotMe: (state, getters) => {
      let list = getters.usersNotMe.map(user => {
        user.initials = getters.getInitials(user.fullName)
        return user
      })

      if (list.length > 5) {
        const additionalNum = list.length - 4
        const moreUsers = {
          initials: '+' + additionalNum,
          firstName: `and ${additionalNum} more`
        }

        list = list.slice(0, 4)
        list.push(moreUsers)
      }
      return list
    },
    getChannelByGuid: state => channelGuid => {
      return state.channels.nodes.find(c => c.guid === channelGuid)
    },
    currentChannel (state, getters) {
      const channel = getters.getChannelByGuid(state.currentChannelGuid)
      const defaultChannel = { users: [] }

      return channel ?? defaultChannel
    },
    getCurrentUser: (state) => {
      return state.currentUser
    },
    getChannels: (state) => {
      return state.channels
    },
    getHasUnreadChannels: (state) => {
      return state.hasUnreadChannels
    },
    getWorker: (state) => {
      return state.worker
    },
    getWorkerBroadcastChannel: (state) => {
      return state.workerBroadcastChannel
    },
    getSound: (state) => {
      return state.sound
    },
    getMuted: (state) => {
      return state.muted
    },
    getTypingTimeout: (state) => {
      return state.typingTimeout
    },
    getNewChat: (state) => {
      return state.newChat
    },
    getCurrentMessages: (state) => {
      return state.currentMessages
    },
    getChatDisclaimer: (state) => {
      return state.chatDisclaimer
    },
    getIsLoadingChannelMessages: (state) => {
      return state.isLoadingChannelMessages
    },
    getAllowSendingAttachments: (state) => {
      return state.allowSendingAttachments
    },
    getConfirmChannelCreationDialog: (state) => {
      return state.confirmChannelCreationDialog
    },
    getIsLoChatEnabled (state) {
      if (state.newChat) {
        return state.isLoChatEnabled === null || state.isLoChatEnabled
      } else {
        const currentChannel = state.channels.nodes.find(c => c.guid === state.currentChannelGuid)
        return currentChannel?.users?.every(lo => lo.chatEnabled === null || lo.chatEnabled)
      }
    },
    getIsSendingMessages: (state) => {
      return state.isSendingMessages
    },
    getSelectedUsers: (state) => {
      return state.selectedUsers
    },
    getSelectedOtherUsers: (state) => {
      return state.selectedOtherUsers
    },
    getCurrentMessagesPageInfo: (state) => {
      return state.currentMessagesPageInfo
    },
    getSomeoneIsTyping: (state) => {
      return state.someoneIsTyping
    },
    getRequireLoanOfficer: (state) => {
      return state.requireLoanOfficer
    },
    getImageFiles: (state) => {
      return state.imageFiles
    },
    getCreateChatError: (state) => {
      return state.createChatError
    }
  },
  mutations: {
    setWorker (state, worker) {
      state.worker = worker
    },
    setWorkerBroadcastChannel (state, workerBroadcastChannel) {
      state.workerBroadcastChannel = workerBroadcastChannel
    },
    setChatDisclaimer (state, disclaimer) {
      state.chatDisclaimer = disclaimer
    },
    setAllowSendingAttachments (state, value) {
      state.allowSendingAttachments = value
    },
    addWorkerBroadcastChannelEventListener (state, { eventName, callback }) {
      state.workerBroadcastChannel.addEventListener(eventName, callback)
    },
    setCurrentUser (state, currentUser) {
      state.currentUser = currentUser
    },
    setViewedDisclaimer (state, value) {
      state.viewedDisclaimer = value
    },
    setRequireLoanOfficer (state, value) {
      state.requireLoanOfficer = value
    },
    updateChannel (state, channel) {
      state.channels = {
        ...state.channels,
        nodes: state.channels.nodes.map(c => c.guid === channel.guid ? channel : c)
      }
    },
    setChannelPageInfo (state, pageInfo) {
      state.channelPageInfo = pageInfo
    },
    setScrollChannelsToTop (state, value) {
      state.scrollChannelsToTop = value
    },
    markAllChannelsAsRead (state) {
      state.channels = {
        ...state.channels,
        nodes: state.channels.nodes.map(channel => {
          return {
            ...channel,
            unread: false
          }
        })
      }
    },
    addChannel (state, channel) {
      state.channels = {
        ...state.channels,
        nodes: [
          channel,
          ...state.channels.nodes
        ]
      }
    },
    setSomeoneIsTyping (state, value) {
      state.someoneIsTyping = value
    },
    addNewMessage (state, message) {
      if (!state.currentMessages.find(m => m.guid === message.guid)) {
        state.currentMessages = [
          ...state.currentMessages,
          message
        ]
      }
    },
    addNewMessages (state, messages) {
      const mergedMessages = [
        ...state.currentMessages,
        ...messages
      ]

      // Remove duplicates (based on message guid)
      state.currentMessages = mergedMessages.reduce((acc, current) => {
        const x = acc.find(m => m.guid === current.guid)
        return !x ? acc.concat([current]) : acc
      }, [])
    },
    updateCurrentMessagesPageInfo (state, pageInfo) {
      state.currentMessagesPageInfo = pageInfo
    },
    resetCurrentMessagesPageInfo (state) {
      state.currentMessagesPageInfo = {
        endCursor: null,
        hasNextPage: false
      }
    },
    setSelectedUser (state, user) {
      state.selectedUsers = user
    },
    setSelectedOtherUsers (state, users) {
      state.selectedOtherUsers = users
    },
    postMessage (state, message) {
      if (state.worker.port) {
        state.worker.port.postMessage(message)
      } else {
        state.worker.postMessage(message)
      }
    },
    setCurrentChannelGuid (state, channelGuid) {
      state.currentChannelGuid = channelGuid
    },
    clearCurrentChannel (state) {
      state.currentChannelGuid = null
      Cookie.remove('currentChannel.guid')
    },
    setCurrentMessages (state, messages) {
      state.currentMessages = messages
    },
    setCreateChatError (state, error) {
      state.createChatError = error
    },
    clearCurrentMessages (state) {
      state.currentMessages = []
    },
    setSoundVolume (state, volume) {
      state.sound.volume = volume
    },
    setMuted (state, value) {
      state.muted = value
    },
    setTypingTimeout (state, timeout) {
      state.typingTimeout = timeout
    },
    setConfirmChannelCreationDialog (state, value) {
      state.confirmChannelCreationDialog = value
    },
    setIsLoChatEnabled (state, value) {
      state.isLoChatEnabled = value
    },
    setNewChat (state, value) {
      state.newChat = value
    },
    setChatToastMessage (state, value) {
      state.toast = value
    },
    setHasUnreadChannels (state, value) {
      state.hasUnreadChannels = value
    },
    setIsLoadingChannelMessages (state, value) {
      state.isLoadingChannelMessages = value
    },
    setIsSendingMessages (state, value) {
      state.isSendingMessages = value
    },
    addChannels (state, newChannels) {
      const channelMuteSettings = JSON.parse(localStorage.getItem('muteNotification') || '[]')

      newChannels.forEach(channel => {
        const muteSetting = channelMuteSettings.find(muteSetting => muteSetting.guid === channel.guid)
        if (muteSetting) {
          channel.muted = muteSetting.notification
        } else {
          channel.muted = false
        }
      })

      state.channels.nodes = [
        ...newChannels
      ]
    },
    setChannelsPageInfo (state, pageInfo) {
      state.channels.pageInfo = pageInfo
    },
    resetChannels (state) {
      state.channels = {
        nodes: [],
        pageInfo: {
          endCursor: null,
          hasNext: false
        }
      }
    },
    setIsInputEmpty (state, value) {
      state.isInputEmpty = value
    },
    setImageFiles (state, value) {
      state.imageFiles = value
    },
    spliceImageFiles (state, index) {
      state.imageFiles.splice(index, 1)
    },
    pushImageFiles (state, value) {
      state.imageFiles.push(value)
    },
    setChannelMuteSetting (state, { currentChannel, muted }) {
      const mutedChannel = state.channels.nodes.find(c => c === currentChannel)
      mutedChannel.muted = muted

      state.channels = {
        ...state.channels,
        nodes: state.channels.nodes.map(c => c.guid === currentChannel.guid ? mutedChannel : c)
      }
    }
  },
  actions: {
    async createChannelAction ({ state, getters, commit, dispatch }, channel) {
      const queryOpts = {
        mutation: createChannel,
        variables: {
          chatUserGuids: channel.chatUserGuids,
          contextGuid: channel.contextGuid,
          contextType: channel.contextType,
          confirmed: !!channel.confirmed
        }
      }
      const data = await apollo.mutate(queryOpts)
      const createChannelData = data.data.createChannel

      if (createChannelData && createChannelData.channel) {
        const newChannel = createChannelData.channel
        const existingChannel = getters.getChannelByGuid(newChannel.guid)

        if (existingChannel) {
          commit('updateChannel', newChannel)
          await dispatch('setCurrentChannelAction', newChannel.guid)
        } else {
          await dispatch('fetchChannelAction', {
            channelGuid: newChannel.guid,
            chatUserGuids: newChannel.userGuidsString
          })
        }

        commit('setNewChat', false)
      } else if (createChannelData.errors && createChannelData.errors.length > 0) {
        const hasWarning = createChannelData.errors.find(e => {
          return e === 'Relationship Warning'
        })

        let errorMessage = createChannelData.errors[0]

        if (hasWarning) {
          errorMessage = 'It looks like one or more of these people are not on the same loan. Are you sure you would like to include them in the same message?'
          commit('setConfirmChannelCreationDialog', true)
          commit('setCreateChatError', errorMessage)
        }
        if (errorMessage) {
          const toast = {
            active: true,
            message: 'Error',
            description: errorMessage,
            event: 'error'
          }
          commit('setChatToastMessage', toast)
        }
        commit('setIsLoadingChannelMessages', false)
      }
    },
    async scrollChannelsAction (context, prevScrollTop = 0) {
      // NOTE: prevScrollTop of 0 will scroll to the bottom of the container

      const ref = document.getElementById('channelList')

      if (ref) {
        ref.scrollTop = prevScrollTop
      }

      return Promise.resolve()
    },
    async scrollMessagesAction (context, prevScrollHeight = 0) {
      // NOTE: prevScrollTop of 0 will scroll to the bottom of the container

      const ref = document.getElementById('channelMessages')

      if (ref) {
        ref.scrollTo({
          top: ref.scrollHeight - prevScrollHeight
        })
      }

      return Promise.resolve()
    },
    clearStateData ({ commit }) {
      commit('setCurrentUser', {})
      commit('setAllowSendingAttachments', false)
      commit('setHasUnreadChannels', false)
      commit('resetChannels')
      commit('setCurrentChannelGuid', null)
      commit('clearCurrentMessages')
      commit('resetCurrentMessagesPageInfo')
      commit('setSelectedUser', [])
      commit('setSelectedOtherUsers', [])
    },
    createOrUpdateChatUsersAndChannel ({ commit }, loanGuid) {
      const queryOpts = {
        mutation: createOrUpdateChatUsersAndChannelForLoan,
        variables: {
          loanGuid: loanGuid
        }
      }
      apollo.mutate(queryOpts)
    },
    async fetchCurrentUserDataAction ({ commit, dispatch }) {
      try {
        const { data } = await apollo.query({ query: getCurrentUser })
        const chatSetting = data.user.account_type === 'team_member'
          ? data.team_member?.chat_setting
          : data.effective_servicer_profile_settings?.chat_setting

        commit('setCurrentUser', data.user)
        commit('setChatDisclaimer', data.effective_servicer_profile_settings.content_setting.chat_disclaimer)
        commit('setRequireLoanOfficer', chatSetting.require_loan_officer_in_every_chat_channel)
        commit('setAllowSendingAttachments', chatSetting.allow_sending_attachments)
        commit('setHasUnreadChannels', data.chatUser?.hasUnreadChannels)

        return Promise.resolve()
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async fetchChannelsAction ({ state, commit }, options) {
      const {
        search = '',
        loanGuids = [],
        resetPagination = false,
        resetCache = false,
        contextGuid = '',
        contextType = ''
      } = options

      if (resetPagination) {
        commit('resetChannels')
      }
      const queryOpts = {
        query: getChannels,
        variables: {
          search,
          loanGuids,
          contextGuid,
          contextType,
          first: 25,
          after: state.channels.pageInfo.endCursor
        }
      }

      try {
        if (resetCache) {
          apollo.cache.reset()
        }
        const { data } = await apollo.query(queryOpts)

        commit('addChannels', data.channels.nodes)
        commit('setChannelsPageInfo', data.channels.pageInfo)

        return Promise.resolve()
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async createSuggestedChannel ({ state, getters, commit, dispatch }, { contextType, contextGuid }) {
      if (!contextType || !contextGuid) return Promise.reject(new Error('`contextType` and `contextGuid` are required'))
      const queryOpts = {
        query: getSuggestedChannels,
        variables: {
          contextGuid: contextGuid,
          contextType: contextType
        }
      }
      try {
        const { data } = await apollo.query(queryOpts)
        const channel = data.suggestedChannels?.nodes[0]
        if (channel) {
          const newChannel = {
            ...getters.currentChannel,
            ...channel,
            chatUserGuids: channel.users.map(u => u.guid),
            contextGuid: contextGuid,
            contextType: contextType
          }

          if (!getters.getChannelByGuid(newChannel.guid)) {
            commit('addChannel', newChannel)
          }

          await dispatch('createChannelAction', newChannel)
        }
        return Promise.resolve()
      } catch (e) {
        console.error(e)
      }
    },
    async fetchChannelAction ({ state, getters, commit, dispatch }, { channelGuid, chatUserGuids }) {
      if (!channelGuid && !chatUserGuids) {
        return Promise.reject(new Error('`channelGuid` or `chatUserGuids` are required'))
      }

      commit('setIsLoadingChannelMessages', true)

      const queryOpts = {
        query: getChannel,
        variables: {
          guid: channelGuid,
          chatUserGuids: chatUserGuids
        }
      }

      try {
        const { data } = await apollo.query(queryOpts)
        const channel = data.channel

        if (channel) {
          const newChannel = {
            ...getters.currentChannel,
            ...channel
          }

          if (!getters.getChannelByGuid(newChannel.guid)) {
            commit('addChannel', newChannel)
          }

          await dispatch('setCurrentChannelAction', newChannel.guid)
        }

        return Promise.resolve()
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async startNewChannelAction ({ commit }) {
      commit('setNewChat', true)
      commit('clearCurrentChannel')
      commit('clearCurrentMessages')

      return Promise.resolve()
    },

    // Not totally sure what the use case for this method is, so this is currently untested
    async createTypedChannelAction ({ state, commit, dispatch }, channel) {
      if (!channel) {
        return Promise.reject(new Error('`channel` is required'))
      }
      const queryOpts = {
        mutation: createTypedChannel,
        variables: {
          channelType: channel.channelType || 'loan_team',
          contextGuid: channel.chatContext.contextGuid,
          contextType: channel.chatContext.contextType
        }
      }

      try {
        const { data } = await apollo.mutate(queryOpts)
        const createTypedChannel = data.createTypedChannel

        if (createTypedChannel?.channel) {
          this.$nextTick(async () => {
            const newChannel = createTypedChannel.channel
            const existingChannel = state.channels.find(c => c.guid === newChannel.guid)

            if (existingChannel) {
              commit('updateChannel', newChannel)
              await dispatch('setCurrentChannelAction', newChannel.guid)
            } else {
              await dispatch('fetchChannelAction', newChannel.guid)
            }

            this.setNewChat(false)
          })
        } else if (createTypedChannel.errors?.length > 0) {
          const relationshipWarning = createTypedChannel.errors.find(e => e === 'Relationship Warning') !== undefined

          if (relationshipWarning) {
            this.setConfirmChannelCreationDialog(true)
          }
        }

        return Promise.resolve()
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async setCurrentChannelAction ({ state, commit, getters, dispatch }, channelGuid) {
      if (!channelGuid) {
        return Promise.reject(new Error('`channelGuid` is required'))
      }

      if (channelGuid !== state.currentChannelGuid) {
        commit('clearCurrentMessages')
        commit('setCurrentChannelGuid', channelGuid)
        Cookie.set('currentChannel.guid', channelGuid)
      }

      const loChatEnabled = getters.currentChannel.users.filter(c => c.chatEnabled !== null).every(c => c.chatEnabled)

      commit('setIsLoChatEnabled', loChatEnabled)
      commit('setSomeoneIsTyping', false)
      dispatch('updateChannelReadAction', channelGuid)

      return Promise.resolve()
    },
    async updateChannelReadAction ({ state, commit, dispatch }, channelGuid) {
      if (!channelGuid) {
        return Promise.reject(new Error('channelGuid is required'))
      }

      const queryOpts = {
        mutation: createOrUpdateChannelRead,
        variables: {
          channelGuid: channelGuid
        }
      }

      try {
        const { data } = await apollo.mutate(queryOpts)
        const hasUnreadChannels = data.createOrUpdateChannelRead.chatUser.hasUnreadChannels

        dispatch('setChannelReadStateAction', {
          channelGuid,
          read: true
        })
        commit('setHasUnreadChannels', hasUnreadChannels)

        return Promise.resolve()
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async updateChannelPreviewText ({ commit, getters }, { channelGuid, previewText, createdAt, unread }) {
      const channel = cloneDeep(getters.getChannelByGuid(channelGuid))

      if (!channel) {
        return Promise.reject(new Error(`No channel found with GUID: ${channelGuid}`))
      }
      channel.previewText = previewText
      channel.updatedAt = createdAt
      channel.unread = unread
      commit('updateChannel', channel)
      return Promise.resolve()
    },
    async setChannelReadStateAction ({ commit, getters }, { channelGuid, read }) {
      const channel = cloneDeep(getters.getChannelByGuid(channelGuid))

      if (!channel) {
        return Promise.reject(new Error(`No channel found with GUID: ${channelGuid}`))
      }

      channel.unread = !read
      commit('updateChannel', channel)

      if (!read) commit('setHasUnreadChannels', true)

      return Promise.resolve()
    },
    async markAllAsReadAction ({ state, commit }) {
      if (!state.hasUnreadChannels) return Promise.resolve()

      const queryOpts = {
        mutation: MarkAllChannelsAsRead,
        variables: {
          guid: state.currentUser.guid
        }
      }

      try {
        const { data } = await apollo.mutate(queryOpts)

        if (data.markAllAsRead?.success) {
          const toast = {
            active: true,
            description: data.markAllAsRead.message || 'Success. Marked all messages as read',
            event: 'success'
          }

          commit('markAllChannelsAsRead')
          commit('setHasUnreadChannels', false)
          commit('setChatToastMessage', toast)
        }

        return Promise.resolve()
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async fetchChannelMessagesAction ({ state, getters, commit }, resetPagination = false) {
      commit('setIsLoadingChannelMessages', true)
      if (resetPagination) {
        commit('resetCurrentMessagesPageInfo')
      }

      try {
        const queryOpts = {
          query: getChannelMessages,
          variables: {
            guid: getters.currentChannel.guid,
            first: 50,
            after: state.currentMessagesPageInfo.endCursor
          },
          fetchPolicy: 'no-cache'
        }

        const data = await apollo.query(queryOpts)
        const pageInfo = data.data.channel.messages.pageInfo
        const messages = data.data.channel.messages.nodes

        commit('addNewMessages', messages)
        commit('updateCurrentMessagesPageInfo', pageInfo)
        commit('setIsLoadingChannelMessages', false)

        return Promise.resolve()
      } catch (e) {
        return Promise.reject(e)
      }
    },
    removeOtherParticipant ({ getters, commit }, user) {
      const otherParticipants = [...getters.getSelectedOtherUsers]
      commit('setSelectedOtherUsers', otherParticipants.filter(u => u.guid !== user.guid))
    },
    addOtherParticipant ({ getters, commit }, user) {
      const otherParticipants = [...getters.getSelectedOtherUsers]
      otherParticipants.push(user)
      commit('setSelectedOtherUsers', otherParticipants)
    }
  }
}
