<template>
  <v-app v-if="$store.borrowerWebContent !== undefined">
    <v-main>
      <router-view />
    </v-main>
    <sn-toast
      v-bind="toast"
      @input="closeToast"
      @actionClicked="toast.actionClicked"
    />
  </v-app>
</template>

<script>
import snContentService from '../../../services/snContentService'
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'App',
  data () {
    return {
      store: this.$store
    }
  },
  computed: {
    ...mapGetters('toast', { toast: 'getToast' })
  },
  created () {
    this.setUpBorrowerWebContent()
  },
  methods: {
    ...mapMutations('toast', ['setToast', 'closeToast']),
    async setUpBorrowerWebContent () {
      const initialRoutes = [
        'login', 'homehub_sso', 'sso_login',
        'onboarding', 'signup', 'ask-for-email', 'register-account',
        'lo_not_found'
      ]
      let sac = location.pathname.split('/').slice(-1)[0]
      if (initialRoutes.includes(sac)) sac = ''
      const result = await snContentService.borrowerWebContent(sac)
      if (result?.data?.content) {
        this.store.borrowerWebContent = JSON.parse(result.data.content)
      }
    }
  }
}
</script>
